import { ptBr as Home } from 'features/Home/locales'
import { ptBr as Login } from 'features/Login/locales'
import { ptBr as Networks } from 'features/Networks/locales'

export const ptBrTransaltion = <const>{
  feature: {
    Home,
    Login,
    Networks,
  },
}

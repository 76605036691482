import { styled } from "@mui/system";

const Wrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100vw',
  height: '100vh',
}));

export function LoadingScreen() {

  return (
    <Wrapper>
      <span>loading...</span>
    </Wrapper>
  )
}
export const ptBr = <const>{
  enter: 'Entrar',
  password: 'Senha',
  email: 'E-mail',
  loginFields: {
    invalidEmail: 'E-mail inválido',
    isRequired: 'Esse campo é obrigatório',
  },
  errors: {
    passwordOrEmail: 'Seu email e/ou senha não correspondem. tente novamente.'
  }
}

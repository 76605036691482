import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface AlertDialogProps {
  isOpen?: boolean;
  title?: string;
  message?: string;
  btnMessage?: string;
  close?: () => void;
}

export default function AlertDialog(
  { isOpen = false,
    title,
    message,
    btnMessage,
    close
  }: AlertDialogProps) {
  return (
    <React.Fragment>
      <Dialog
        open={isOpen}
        keepMounted
        onClose={close}
        aria-describedby="alert-dialog-slide-description"
        componentsProps={{
          backdrop: {
            style: {
              background: 'rgba(0, 0, 0, 0.32)',
              boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
              backdropFilter: 'blur(15.8px)',
            }
          }
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {message}
            </DialogContentText>
          </DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={close}>{btnMessage}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment >
  );
}

import Axios, { AxiosInstance } from "axios";

import { useAuthStore } from "features/Auth/storage";

const API_URL = process.env.VITE_API_URL

export function getAuthToken(): string | undefined {
  return useAuthStore.getState().user?.token
}

function withAuthorization(instance: AxiosInstance) {
  instance.interceptors.request.use((config) => {
    const token = getAuthToken()
    if (token)
      config.headers.Authorization = `Bearer ${token}`

    return config
  })

  return instance
}

export const protectedApi = withAuthorization(
  Axios.create({
    baseURL: API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
)
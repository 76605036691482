import { Close } from '@mui/icons-material'
import {
  Alert,
  AlertProps,
  IconButton,
  Snackbar as MUISnackbar,
} from '@mui/material'

import { create } from '@/utils'

type SnackbarState = {
  opened: boolean
  severity?: AlertProps['color']
  message: string
  autoHideDuration: number
}
type SnackbarActions = {
  hide: () => void
  show: (options: {
    message: string
    autoHideDuration?: number
    severity?: SnackbarState['severity']
  }) => void
}

type SnackbarStore = SnackbarState & SnackbarActions

const initialState: SnackbarState = {
  opened: false,
  message: '',
  autoHideDuration: 6000,
}

export const useSnackbar = create<SnackbarStore>((set) => ({
  ...initialState,
  show({ message, autoHideDuration, severity }) {
    set({
      opened: true,
      severity,
      message,
      autoHideDuration: autoHideDuration || 6000,
    })
  },
  hide() {
    set({ opened: false })
  },
}))

export function Snackbar() {
  const { opened, autoHideDuration, hide, severity, message } = useSnackbar()

  if (!severity)
    return (
      <MUISnackbar
        open={opened}
        autoHideDuration={autoHideDuration}
        onClose={hide}
        message={message}
        action={
          <IconButton onClick={hide} color="secondary">
            <Close />
          </IconButton>
        }
      />
    )

  return (
    <MUISnackbar
      open={opened}
      autoHideDuration={autoHideDuration}
      onClose={hide}
    >
      <Alert onClose={hide} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </MUISnackbar>
  )
}

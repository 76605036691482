import { AxiosError } from 'axios'

import { useAuthStore } from 'features/Auth/storage'
import { networks } from 'services/api/networks'
import { create } from 'utils/index'

import { parseNetworksData } from '../utils'
import { NetworksState, NetworksStore } from './types'

import { LoadingStatus } from '@/constants'

const initialDialog = {
  title: 'Sessão expirada',
  message: 'Para continuar, por favor faça seu login novamente.',
  btnMessage: 'Ir para login',
}

const expiredSessionDialog = {
  ...initialDialog,
  isOpen: true,
  close: () => {
    useAuthStore.getState().clearSession()
    useNetworks.setState({
      dialog: initialDialog,
    })
  }
}

const initialState: NetworksState = {
  search: '',
  status: LoadingStatus.Idle,
  data: null,
  pages: 1,
  currentPage: 1,
  errors: undefined,
  dialog: undefined,
}

export const useNetworks = create<NetworksStore>((set) => ({
  ...initialState,
  setSearch(search) {
    set({ search: search ?? '' })
  },
  clearSearch() {
    set({ ...initialState })
  },
  async fetchNetworks(page = 1, query?: string) {
    set({ status: LoadingStatus.Pending })

    try {
      const res = await networks(page, query)
      const data = parseNetworksData(res.data?.networks ?? [])

      set({
        data,
        currentPage: data.length ? page : 0,
        pages: res.data?.pages ?? 0,
        status: LoadingStatus.Success,
      })
    } catch (err) {
      set({ status: LoadingStatus.Failure })

      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          set({
            errors: err.message,
            dialog: expiredSessionDialog,
          })
        }
      }
    }
  },
}))

import { useEffect } from 'react';

import AlertDialog from 'components/ui/Dialog/Dialog';
import { Snackbar } from 'components/ui/Snackbar'
import { useAuthStore } from 'features/Auth/storage';
import { useNetworks } from 'features/Networks/store/useNetworks';

import { Routes } from './router/Routes'
import { ThemeProvider } from './styles'

function App() {
  const { isExpiredSession } = useAuthStore()
  const { dialog } = useNetworks()

  useEffect(() => { isExpiredSession() }, [])

  return (
    <ThemeProvider>
      <AlertDialog {...dialog} />
      <Snackbar />
      <Routes />
    </ThemeProvider>
  )
}

export default App

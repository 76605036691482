import { AxiosError, AxiosResponse } from 'axios'
import { fromUnixTime, isAfter } from 'date-fns'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { login, logout, refresh } from 'services/api/auth'

import { AuthStore, Credentials, Persistor } from '../types'

import { LoadingStatus } from '@/constants'

const initialState = {
  loading: LoadingStatus.Idle,
  isAuthenticated: false,
  errors: null,
  user: null,
}

const OK_STATUS = [200, 201]

export const useAuthStore = create<AuthStore>(
  (persist as Persistor)(
    (set, get) => ({
      ...initialState,

      async signIn(credentials: Credentials) {
        set({ loading: LoadingStatus.Pending })

        let res: AxiosResponse | undefined

        try {
          res = await login(credentials)
        } catch (err) {
          if (err instanceof AxiosError) {
            res = err.response
          }
        }

        if (!res?.data) {
          set({ loading: LoadingStatus.Failure })
          return
        }

        if (OK_STATUS.includes(res?.status)) {
          set({
            isAuthenticated: true,
            loading: LoadingStatus.Success,
            errors: null,
            user: {
              email: credentials.email,
              ...res.data,
            }
          })
        } else {
          set({
            loading: LoadingStatus.Failure,
            errors: res.data.errors,
          })
        }
      },

      signOut() {
        const user = get().user

        if (user?.token) {
          logout({
            token: user?.token
          }).then(() => {
            set(() => ({ ...initialState }))
          })
        }
      },

      clearSession() {
        set(() => ({ ...initialState }))
      },

      isExpiredSession() {
        const user = get().user

        if (!user?.expires_at) return

        const now = fromUnixTime(Math.floor(Date.now() / 1000));
        const expired_at = fromUnixTime(user.expires_at);

        if (isAfter(now, expired_at)) {
          get().signOut()
        } else {
          get().refreshSession()
        }
      },

      async refreshSession() {
        const user = get().user

        if (!user?.token) return

        const { token } = user

        try {
          const res = await refresh({
            token: token
          })

          set({
            isAuthenticated: true,
            loading: LoadingStatus.Success,
            errors: null,
            user: {
              email: user.email,
              ...res.data,
            }
          })
        } catch (err) {
          set({ loading: LoadingStatus.Failure })

          throw err
        }
      }
    }),
    {
      name: 'backoffice-auth-storage',
      storage: createJSONStorage(() => localStorage),
    }
  )
)

import { format, parseISO } from "date-fns"

export const formatCPForCPNJ = (value: string) => {
  value = value.replace(/\D/g, "")
  const len = value.length
  if (len < 12) {
    value = value.replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2")
  } else {
    value = value.replace(/^(\d{2})(\d)/, "$1.$2")
      .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      .replace(/\.(\d{3})(\d)/, ".$1/$2")
      .replace(/(\d{4})(\d)/, "$1-$2")
  }
  return value.substring(0, 18)
}

export const formatAccessDate = (value: string | undefined) => {
  if (!value) return 'Sem acessos'
  if (parseInt(value?.substring(0, 4)) < 2000) return 'Data inválida'
  return format(parseISO(value), 'dd/MM/yyyy - HH:mm')
}
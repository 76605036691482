import { lazy, Suspense } from 'react';

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { useAuthStore } from 'features/Auth/storage';
import { LoadingScreen } from 'screens/Loading'

const Home = lazy(() => import('screens/Home'));
const Login = lazy(() => import('screens/Login'));


export function Routes() {
  const { isAuthenticated } = useAuthStore()

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Suspense fallback={<LoadingScreen />}>
          {isAuthenticated ? <Home /> : <Login />}
        </Suspense>
      ),
    },
  ])

  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

import { Credentials, LoginResponse, TokenPayload } from 'features/Auth/types'

import { protectedApi } from '..'
import apiRoutes from '../routes'


export const login = (credentials: Credentials) =>
  protectedApi.post<LoginResponse>(apiRoutes.login, JSON.stringify(credentials))

export const logout = (token: TokenPayload) =>
  protectedApi.post<LoginResponse>(apiRoutes.logout, JSON.stringify(token))

export const refresh = (token: TokenPayload) =>
  protectedApi.post<LoginResponse>(apiRoutes.refresh, JSON.stringify(token))
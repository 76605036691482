const apiRoutes = {
  login: `/v1/auth/login`,
  logout: `/v1/auth/logout`,
  refresh: `/v1/auth/refresh`,

  networks: `/v1/dawa/networks/paginate`,

  updateEmail: `/v1/dawa/users/email-update`,
  updatePassword: `/v1/users/password-update`,
}

export default apiRoutes

export const ptBr = <const>{
  editEmailModal: {
    save: 'Salvar',
    email: 'E-mail',
    newEmail: 'Novo e-mail',
    invalidEmail: 'E-mail inválido',
    isRequired: 'Esse campo é obrigatório',
    success: 'E-mail alterado com sucesso!'
  },
  resetPassword: {
    feedback: {
      fail: 'Falhou ao tentar alterar a senha!',
      success: 'Senha alterada com sucesso!',
    },
    required: 'Esse campo é obrigatório',
    minLength: 'Mínimo de {{min}} caracteres',
    email: 'E-mail',
    prefix: 'Prefixo',
    submit: 'Salvar'
  }
}

import { NetworksResponse } from 'features/Networks/types'

import { protectedApi } from '..'
import apiRoutes from '../routes'

export const networks = (page = 1, query = '') =>
  protectedApi.get<NetworksResponse>(apiRoutes.networks, {
    params: {
      page,
      search_term: query,
      size: 10,
      order: 'name',
      direction: 'asc',
    }
  })


import { Network } from "./types"

import { formatAccessDate, formatCPForCPNJ } from "@/helpers"

const DID_NOT_DISPENSE = 'Não dispensou'

export const parseNetworksData = (networks: Network[]): Network[] => {
  return networks.map((network) => ({
    email: network.email,
    id: network.id,
    pharmacy_name: network.pharmacy_name,
    pharmacy_cnpj: formatCPForCPNJ(network.pharmacy_cnpj),
    last_access: formatAccessDate(network.last_access),
    quantity_dispensation: network.quantity_dispensation ?? DID_NOT_DISPENSE,
  }))
}
